<template>
    <Modal
        class="wrap"
        v-model="modal"
        :title="$t('pages.program.addBkgMusic')"
        :width="880"
        :mask-closable="false"
        @on-visible-change="handleVisibleChange">
        <Row class="search-bar">
            <Col span="13">
                <RadioGroup class="radio-group-button" v-if="is_org" v-model="bucket" type="button" @on-change="handleSpace">
                    <!-- <Radio label="user">个人空间</Radio> -->
                    <Radio label="org">{{ $t('navigation.companySpaces') }}</Radio>
                </RadioGroup>
                <Dropdown v-if="is_org && bucket === 'org'" class="dropdown dropdown-structure" trigger="click" placement="bottom-start" @on-visible-change="handleTreeDropdown">
                    <Icon v-if="nodeList.length" class="close" type="ios-close-circle" @click="clearNodeList()" />
                    <Button type="text">
                        {{ $t('navigation.structure') }}
                        <div v-if="nodeList.length" class="tree line-overflow">
                            <span>:</span>
                            <span v-for="(item,index) in nodeList" :key="item.department_id">{{ item.title }}
                                <span v-if="index !== nodeList.length - 1">,</span>
                            </span>
                        </div>
                        <Icon type="ios-arrow-down"></Icon>
                    </Button>
                    <DropdownMenu slot="list">
                        <Tree :data="data" show-checkbox @on-check-change="handleTree" @on-select-change="handleSelectChange"></Tree>
                    </DropdownMenu>
                </Dropdown>
            </Col>
            <Col span="11" class="clearfix">
                <Button v-allow="'content'" class="float-right" type="primary" @click="handleUpload">{{ $t('pages.space.uploadMaterials') }}</Button>
                <Input v-model="query" class="float-right margin-right" clearable :placeholder="$t('pages.space.inputNameSearch')" style="width: auto" @keyup.native.enter="searchNew" @on-clear="searchNew" >
                    <Icon type="ios-search pointer" slot="suffix" @click="searchNew" />
                </Input>
            </Col>
        </Row>
        <no-data v-show="list.length === 0" :text="$t('pages.space.noMaterials')"></no-data>
        <RadioGroup v-show="list.length > 0" v-model="audio.rowid" class="audio-block" >
            <div :class="['audio-item', item.selected ? 'target': '']" v-for="item in list" :key="item.rowid">
                <div class="img-wrap">
                    <img class="image-center" :src="`/cym/${item.uri}/thumbnail`">
                </div>
                <Radio class="radio" :label="item.rowid" @click.native="handleAudio($event, item)"><span>&nbsp;</span></Radio>
                <div class="name line-overflow">{{ item.name }}</div>
            </div>
            <Spin fix size="large" v-if="loading"></Spin>
        </RadioGroup>
        <Page
            v-show="list.length > 0"
            class="page"
            :total="total"
            :current="current"
            :page-size="pageSize"
            show-elevator
            @on-change="nextPage" />

        <div slot="footer" style="margin-right: 15%;">
            <Button type="default" @click="cancel">{{ $t('common.cancel') }}</Button>
            <Button type="primary" :loading="loading" @click="submit">{{ $t('common.confirm') }}</Button>
        </div>
        <upload-modal
            ref="upload"
            class="audio-upload"
            :show="uploadModalShow"
            :bucket_token="bucket_token"
            :multiple="false"
            :formats="formats"
            @confirm="handleUploadConfirm"
            @cancel="handleUploadCancel"
            @show="handleUploadModal"
        ></upload-modal>
    </Modal> 
</template>
<script>
    import uploadModal from 'views/components/upload'
    import noData from '@/views/components/noData'
    import { dropdownTree } from '@/mixins/index'

    export default {
        name: "",
        mixins: [dropdownTree],
        components: {
            uploadModal,
            noData
        },
        props: {
            show: {
                default: false
            },
            selectedAudio: {
                default: null
            },
            is_org: {
                default: ''
            }
        },
        data () {
            return {
                modal: false,
                loading: false,
                bucket: this.is_org?'org':'user',
                query: '',
                uploadModalShow: false,
                audio: {
                    rowid: ''
                },
                current: 1,
                pageSize: 10,
                total: 0,
                list: [],
                nodeList: [],
                treeList: [],
                data: [],
                formats: ['mp3', 'wav', 'flac']
            }
        },
        computed: {
            bucket_token() {
                return this.$store.state.default_bucket_tokens[this.bucket]
            },
            tree_data() {
                return [this.$store.state.up_tree]
            }
        },
        watch: {
            show(val) {
                this.modal = val
                if (val) {
                    this.data = this.handleRecursive(this.tree_data)
                    this.getData()
                }

                if (this.selectedAudio) {
                    let { rowid, name, uri, media_type  } = this.selectedAudio
                    this.$set(this.audio, 'rowid', rowid)
                    this.$set(this.audio, 'name', name)
                    this.$set(this.audio, 'uri', uri)
                    this.$set(this.audio, 'media_type', media_type)
                } else {
                    this.audio = {
                        rowid: ''
                    }
                }
            }
        },
        methods: {
            handleVisibleChange(e) {
                if (!e) {
                    this.cancel()
                }
            },
            cancel() {
                this.query = ''
                this.current = 1
                this.list = []
                this.total = 0
                this.audio = {
                    rowid: ''
                }
                this.data = []
                this.treeList = []
                this.nodeList = []
                this.bucket = this.is_org?'org':'user'
                this.$emit('cancel', this.audio)
            },
            submit() {
                if (!this.audio.rowid) {
                    return this.$Message.error(this.$t('pages.space.select'))
                }
                this.$emit('confirm', JSON.parse(JSON.stringify(this.audio)))
                this.cancel()
            },
            searchNew() {
                if (this.loading) return
                this.current = 1
                this.getData()
            },
            nextPage(e) {
                this.current = e
                this.getData()
            },
            getData() {
                let params = {
                        query: this.query,
                        type_list: "audio",
                        start: (this.current - 1) * this.pageSize,
                        count: this.pageSize
                    },
                    len = this.nodeList.length,
                    store = JSON.parse(JSON.stringify(this.audio));
                if (this.is_org && this.bucket === 'org') {
                    if (len) {
                        let bucket_token_list = []
                        this.nodeList.map((item) => {
                            bucket_token_list.push(item.bucket_token)
                        })
                        params.bucket_token_list = JSON.stringify(bucket_token_list)
                    } else {
                        this.treeList = []
                        this.getTreeList(this.data, 'bucket_token')
                        params.bucket_token_list = JSON.stringify(this.treeList)
                    }
                } else {
                    params.bucket_token = this.$store.state.default_bucket_tokens.user
                }
                this.loading = true
                this.$api.get('materials/search', { params }).then(({ data, total_count, page })=>{
                    if (page === this.current) {
                        this.list = data.map((item) => {
                            if (store.rowid === item.rowid) {
                                item.selected = true
                            } else {
                                item.selected = false
                            }
                            return item
                        })
                        this.total = total_count
                        this.loading = false
                    }
                }).catch(()=>{
                    this.loading = false
                }).finally(()=>{
                    this.audio = store
                })
            },
            handleUpload() {
                if (this.$refs['upload'].defaultList.length > 0) {
                    this.$refs['upload'].loadingModalShow = true
                    return
                }
                this.$el.querySelector('.audio-upload').getElementsByTagName('input')[0].click()
                this.$refs['upload'].init = true

            },
            handleUploadModal() {
                this.uploadModalShow = true
            },
            handleUploadConfirm(rowid_list) {
                this.handleUploadCancel()
                console.log(rowid_list)
                // this.audio = rowid_list[0]
                this.nodeList = []
                this.current = 1
                this.getData()
            },
            handleUploadCancel() {
                this.uploadModalShow = false
            },
            handleSpace() {
                this.current = 1
                this.getData()
            },
            handleAudio(e, item) {
                let ele = e.currentTarget.parentElement,
                    { rowid, name, uri, material_info } = item;
                ele.className = "audio-item target"
                this.audio.name = name
                this.audio.uri = uri
                this.audio.media_type = material_info.media_type
                this.audio.rowid = rowid
            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
@import '~less/color';
.dropdown {
    position: relative;
    vertical-align: bottom;
    .close {
        display: none;
    }
    .label-mag {
        cursor: pointer;
        padding: 7px 0 7px 4px;
        font-size: 14px;
    }
    &:hover {
        .close {
            display: block;
            position: absolute;
            right: 0px;
            top: 0px;
        }
    }
    .tree {
        display: inline-block;
        max-width: 100px;
        vertical-align: bottom;
    }
}
.audio-block {
    .audio-item {
        position: relative;
        display: inline-block;
        margin: 0 10px 10px 0;
        width: 128px;
        .radio {
            position: absolute;
            top: 0;
            right: 0;
            margin-right: 0;
            width: 100%;
            height: 100%;
        }
        .img-wrap {
            position: relative;
            width: 128px;
            height: 128px;
            background: #7f7f7f;
            box-shadow: 0px 3px 6px 0px rgba(207,207,207,0.50);
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-height: 100%;
                max-width: 100%;
            }
        }
        .name {
            width: 100%;
            font-size: 16px;
            text-align: center;
        }
    }
    .target {
        .img-wrap {
            border: 1px solid @primary-color;
        }
    }
}
@deep: ~">>>";
.radio @{deep} .ivu-radio {
    position: absolute;
    right: 8px;
    top: 8px;
}
.radio-group-button {
    margin-left: -15px;
}
</style>