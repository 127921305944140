<template>
    <Modal
        class="wrap"
        v-model="modal"
        :title="$t('pages.space.uploadMaterials')"
        :mask-closable="false"
        :closable="false"
        :transfer="false"
        width="940"
        @click.native="handelLabelWrapCancel">
       <Poptip
            class="poptip-close"
            width="300"
            confirm
            placement="bottom-end"
            :title="$t('pages.space.closeTip2')"
            :cancel-text="$t('pages.space.think')"
            :ok-text="$t('pages.space.confirmCancel')"
            @on-ok="cancel">
            <Icon class="pointer iconClose" type="ios-close" size="31" />
        </Poptip>
        <div class="section">
            <Row>
                <Col span="18" class="left-bar">
                    <ul class="img-block-wrap clearfix">
                        <li class="img-block pointer">
                            <Upload
                                ref="upload"
                                class="upload"
                                action=""
                                :multiple="multiple"
                                :before-upload="handleBeforeUpload">
                                <div class="upload-icon-wrap">
                                    <Icon class="icon" type="md-add" size="42" />
                                </div>
                            </Upload>
                        </li>
                        <li class="img-block" v-for="(item,index) in defaultList" :key="index">
                            <div class="img-wrap">
                                <template v-if="item.status === 'finished'">
                                    <img class="image-center" :src="item.cover_url" @click="handlePreview(index)">
                                </template>
                                <template v-else>
                                    <Progress class="progress" :percent="item.percentage" hide-info></Progress>
                                </template>
                                <div class="icon-wrap pointer">
                                    <i class="iconfont icon-lajitong icon-remove" @click="handleRemove(index)"></i>
                                </div>
                            </div>
                            <Input v-if="item.status === 'finished'" v-model.trim="item.name" :border="false" class="name" size="small" @on-change="handleName" @paste.native="handleStop" @copy.native="handleStop" @cut.native="handleStop" @keydown.native="handleStop" @keyup.native="handleStop"/>
                        </li>
                    </ul>
                </Col>
                <Col span="6" class="right-bar">
                    <!-- <Icon type="ios-bookmark-outline" /> -->
                    {{ $t('pages.label.setLabel') }}
                    <div>
                        <Tooltip :content="item.name" theme="light" transfer v-for="item in selectedList" :key="item.rowid" :delay="1000">
                            <Button class="label" size="small" type="default" shape="circle">
                                {{ item.name }}
                                <Icon class="close" type="ios-close-circle" @click="handleRemoveLabel(item.rowid)" />
                            </Button>
                        </Tooltip>
                        <Button class="label" size="small" shape="circle" icon="ios-add" @click.stop="handelLabelWrapShow"></Button>
                    </div>
                </Col>
            </Row>
            <div class="add-label-wrap" v-if="labelWrapShow" @click.stop="handelLabelWrapShow">
                <Input v-model.trim="query" clearable suffix="ios-search" :placeholder="$t('pages.label.addOrSearch')" @on-change="handleSearch" @paste.native="handleStop" @copy.native="handleStop" @cut.native="handleStop" @keydown.native="handleStop" @keyup.native="handleStop" />
                <Tooltip :content="query" theme="light" v-if="showAdd && query.length <= 32" :delay="1000">
                    <div class="add line-overflow pointer" type="default" shap="circle" @click="handleAdd">{{ $t('pages.label.create') }}"{{ query }}"</div>
                </Tooltip>
                <load-more :onRefresh="handleReachBottom" :disabled="isLoaded" height="200px">
                    <CheckboxGroup v-model="value" @on-change="checkAllGroupChange">
                        <Tooltip class="tooltip" :content="item.name" theme="light" placement="right" transfer v-for="item in list" :key="item.rowid" :delay="1000">
                            <Checkbox class="checkbox" :label="item.rowid" @click.native="handleCheckbox(item.rowid)">{{ item.name }}</Checkbox>
                        </Tooltip>
                    </CheckboxGroup>
                </load-more>
            </div>
        </div>
        <div slot="footer" class="modal-footer">
            <Poptip
                class="poptip"
                confirm
                placement="top-end"
                :title="$t('pages.space.closeTip2')"
                :cancel-text="$t('pages.space.think')"
                :ok-text="$t('pages.space.confirmCancel')"
                @on-ok="cancel">
                <Button type="default">{{ $t('common.cancel') }}</Button>
            </Poptip>
            <!-- <Button type="default" @click="cancel">{{ $t('common.cancel') }}</Button> -->
            <Button type="primary" :loading="loading" @click="submit">{{ $t('common.confirm') }}</Button>
        </div>
        <loading-modal
            :show="loadingModalShow"
            :percent="percent"
            :title="loadingTitle"
            @cancel="handleLoadingModalCancel"
        ></loading-modal>
        <failed-modal
            :show="failedModalShow"
            :list="block_list"
            @cancel="hideFailedModal"
        ></failed-modal>
    </Modal>
</template>
<script>
    import loadingModal from './loadingModal'
    import failedModal from './failedModal'
    import loadMore from '@/views/components/loadMore'
    import { PreviewImage } from 'components/preview.js'
    import utils from '@/libs/util.js'
    import checkUploadStatus from '@/mixins/checkUploadStatus'

    export default {
        name: "",
        mixins: [checkUploadStatus],
        components: {
            loadMore,
            loadingModal,
            failedModal
        },
        props: {
            show: {
                default: false
            },
            bucket_token: {
                default: null
            },
            multiple: {
                default: true
            },
            formats: {
                default: ()=>{
                    //mps文件类型？
                    return ['3gp', 'asf', 'avi', 'dat', 'dv', 'flv', 'f4v', 'm2t', 'm3u8', 'm4v', 'mj2', 'mjpeg', 'mkv', 'mov', 'mp4', 'mpe', 'mpg', 'mpeg', 'ogg', 'qt', 'rm', 'rmvb', 'swf', 'ts', 'vob', 'wmv', 'webm', 'bmp', 'jpg', 'png', 'jpeg', 'mp3', 'wav', 'flac']
                }
            },
        },
        data () {
            return {
                init: false,
                modal: false,
                loading: false,
                defaultList: [],
                selectedList: [],
                query: '',
                showAdd: false,
                list: [],
                value: [],
                labelWrapShow: false,
                current: 1,
                pageSize: 10,
                itemRowid: null,
                searching: false,
                isLoaded: false,
                count: 0,
                loadingTitle: '',
                rowid_list: [],
            }
        },
        computed: {},
        watch: {
            show(val) {
                this.modal = val
                if (val) {
                    this.loadingTitle = this.$t('pages.space.uploading')
                    this.getLabelList()
                }
            },
            value: {
                handler(val) {
                    let len = val.length,
                        len2 = this.list.length,
                        len3 = this.selectedList.length,
                        result = [];
                    for (let i = 0; i < len; i++) {
                        let existed = false
                        for (let k = 0; k < len3; k++) {
                            if (val[i] === this.selectedList[k].rowid) {
                                existed = true
                                break;
                            }
                        }
                        if (!existed) {
                            for (let j = 0; j < len2; j++) {
                                if (val[i] === this.list[j].rowid)  {
                                    result.push(this.list[j])
                                    break;
                                }
                            }
                        }
                    }
                    this.selectedList.push(...result)
                },
                deep: true
            }
        },
        methods: {
            handleStop(e) {
                e.stopPropagation()
            },
            cancel() {
                this.$emit('cancel')
                this.current = 1
                this.list = []
                this.value = []
                this.selectedList = []
                this.defaultList.map((item) => {
                    window.URL.revokeObjectURL(item.cover_url)
                    window.URL.revokeObjectURL(item.preview_url)
                })
                this.defaultList = []
                this.count = 0
                this.block_list = []
                this.loading = false
                this.percent = 0
                this.loadingTitle = this.$t('pages.space.uploading')
                this.rowid_list = []
                this.switch = false
                this.loadingModalShow = false
            },
            async submit() {
                let len = this.defaultList.length;
                if (!len) return this.cancel()
                this.loading = true
                for (let i = 0; i < len; i++) {
                    if (this.defaultList[i].name === '') {
                        this.loading = false
                        return this.$Message.error(this.$t('pages.space.nameRequired'))
                    }
                    if (this.defaultList[i].name.length > 32) {
                        this.loading = false
                        return this.$Message.error(this.$t('pages.space.nameLength'))
                    }
                    if (this.defaultList[i].file.type.indexOf('image') !== -1) {
                        if (this.defaultList[i].file.size / 1024 / 1024 > 20) {
                            this.loading = false
                            return this.$Message.error(this.$t('pages.space.imageSizeLimit'))
                        }
                        await new Promise((resolve) => {
                            let img = new Image(),
                                _this =  this;
                            img.src = this.defaultList[i].preview_url
                            img.onload = function() {
                                if (this.width > 30000 || this.height > 30000) {
                                    _this.loading = false
                                    _this.$Message.error(_this.$t('pages.space.imageSizeLimit2'))
                                }
                                if (this.width * this.height > 250000000) {
                                    _this.loading = false
                                    _this.$Message.error(_this.$t('pages.space.imageSizeLimit3'))
                                }
                                resolve()
                            }
                        })
                    }
                    if (this.defaultList[i].file.type.indexOf('video') !== -1) {
                        if (this.defaultList[i].file.size / 1024 / 1024 / 1024 > 1) {
                            this.loading = false
                            return this.$Message.error(this.$t('pages.space.videoSizeLimit'))
                        }
                    }
                    if (this.defaultList[i].file.type.indexOf('audio') !== -1) {
                        if (this.defaultList[i].file.size / 1024 / 1024 / 1024 > 1) {
                            this.loading = false
                            return this.$Message.error(this.$t('pages.space.audioSizeLimit'))
                        }
                    }
                }
                if (!this.loading) return
                this.loadingModalShow = true
                for (let i = 0; i < len; i++) {
                    let formData = new FormData()
                    formData.append(this.defaultList[i].name, this.defaultList[i].file)
                    formData.append('bucket_token', this.bucket_token)
                    formData.append('label_id_list', JSON.stringify(this.value))
                    await this.upload(formData)
                }
            },
            upload(formData) {
                return this.$api.post('materials', formData).then(({ rowid_list, pass_list })=>{
                    if (pass_list && pass_list.length) {
                        this.switch = false
                    } else {
                        this.switch = true
                        this.rowid_list.push(...rowid_list)
                    }
                }).catch(()=>{}).finally(()=>{
                    let denominator = 1
                    if (this.switch) {
                        denominator = 2
                    }
                    this.count += 1
                    this.percent = parseInt(Number((this.count  / this.defaultList.length).toFixed(2)) * 100 / denominator)
                    if (this.count >= this.defaultList.length) {
                        if (!this.switch) {
                            let _this = this
                            setTimeout(function() {
                                _this.$emit('confirm')
                                _this.cancel()
                            }, 500)
                            return
                        }
                        this.loadingTitle = this.$t('pages.space.checking')
                        clearInterval(this.timer)
                        const params = {
                            bucket_token: this.bucket_token,
                            rowid_list: JSON.stringify(this.rowid_list)
                        }
                        this.timer = setInterval(this.check_upload_status(params), 1000)
                    }
                })
            },
            handleBeforeUpload(file) {
                if (this.loading) return
                if (this.defaultList.length >= 20) {
                    this.$Message.warning(this.$t('pages.space.fileCountLimit'))
                    return
                }
                let format = file.name.match(/.*\.(.*)/)[1].toLowerCase(),
                    type = file.type,
                    _this = this,
                    params = {
                        name: file.name.match(/(.*)\..*$/)[1].slice(0, 32),
                        cover_url: "",
                        preview_url: URL.createObjectURL(file),
                        type: type,
                        percentage: 0,
                        file: file,
                        status: ''
                    }
                if (this.formats.indexOf(format) === -1) {
                    this.$Message.error(this.$t('pages.space.unsupportedFormat'))
                    return false
                }

                if (type.indexOf('audio') !== -1) {
                    params.cover_url = require('assets/audio.png')
                    params.type_text = this.$t('pages.space.audio')
                    params.format_name = format
                } else if (type.indexOf('image') !== -1) {
                    params.cover_url = URL.createObjectURL(file)
                    params.format_name = format
                    params.type_text = this.$t('pages.space.image')
                    if (type.indexOf('gif') !== -1 || type.indexOf('apng') !== -1) {
                        params.type_text = this.$t('pages.space.gif')
                    }
                    let img = new Image()
                    img.src = params.cover_url
                    img.onload = function() {
                        params.resolution = `${this.width}x${this.height}PX`
                    }
                } else {
                    params.cover_url = require('assets/video.png')
                    params.type_text = this.$t('pages.space.video')
                    params.format_name = format
                }
                _this.defaultList.push(params)

                utils.calculateMd5(file, function (percentage) {
                    params.percentage = percentage
                    if (percentage === 100) {
                        params.status = 'finished'
                    }
                    if (_this.init) {
                        _this.$emit('show')
                        _this.init = false
                    }
                })


                return false
            },
            handlePreview(index) {
                let sources = this.defaultList.map((item) => {
                    let type = item.type
                    let params = {
                            src: item.preview_url,
                            name: item.name,
                            resolution: item.resolution,
                            type_text: item.type_text,
                            format_name: item.format_name
                        },
                        size = item.file.size/(1024*1024);
                    if (size >= 1) {
                        params.bytes_size = Math.round(size*10)/10 + 'M'
                    } else if (size >= 0 && size < 1) {
                        params.bytes_size =  Math.round(10*item.file.size/1024)/10 + 'KB'
                    }

                    if (type.indexOf('image') !== -1) {
                        params.type = 'image'
                    } else if (type.indexOf('video') !== -1) {
                        params.type = 'video'
                    } else {
                        params.type = 'audio'
                    }
                    return params
                })
                PreviewImage({
                    sources: sources,
                    index: index
                })
            },
            handleRemove(index) {
                this.defaultList.splice(index, 1)
            },
            handleRemoveLabel(rowid) {
                let index = this.value.indexOf(rowid),
                    len = this.selectedList.length;
                if (index !== -1) {
                    this.value.splice(index, 1)
                }
                for (let i =0; i < len; i++) {
                    if (rowid == this.selectedList[i].rowid) {
                        this.selectedList.splice(i, 1)
                        break;
                    }
                }
            },
            checkAllGroupChange(value) {
                if (value.indexOf(this.itemRowid) === -1) {
                    let len = this.selectedList.length,
                        index = null;
                    for(let i = 0; i < len; i++) {
                        if (this.itemRowid === this.selectedList[i].rowid) {
                            index = i
                            break;
                        }
                    }
                    this.selectedList.splice(index, 1)
                }
            },
            handleCheckbox(rowid) {
                this.itemRowid = rowid
            },
            handleSearch() {
                if (this.searching) return
                if (!this.query) {
                    this.showAdd = false
                    this.handleClear()
                    return
                }
                this.current = 1
                this.list = []
                this.getLabelList().then((data) => {
                    if (data.length === 0 && this.query) {
                        this.showAdd = true
                    }
                    if (this.query) {
                        if (data.length === 0) {
                            this.showAdd = true
                        } else {
                            let show = true,
                                len = data.length;
                            for (let i = 0; i < len; i++) {
                                if (data[i].name === this.query) {
                                    show = false
                                    break;
                                }
                            }
                            if (show) this.showAdd = true
                        }
                    }
                })
            },
            handleAdd() {
                this.current = 1
                this.list = []
                let params = {
                        name: this.query,
                        bucket_token: this.bucket_token
                    }
                this.$api.post('labels', params).then(({ rowid }) => {
                    this.showAdd = false
                    this.query = ''
                    this.getLabelList().then(() => {
                        this.value.push(rowid)
                    })
                    this.$Message.success(this.$t('message.success'));
                    this.$emit('refresh')
                })
            },
            getLabelList() {
                let params = {
                    query: this.query,
                    bucket_token: this.bucket_token,
                    start: (this.current - 1) * this.pageSize,
                    count: this.pageSize
                }
                this.searching = true
                return this.$api.get('labels/list', { params }).then(({ data, total_count }) => {
                    if (data.length !== 0) {
                        this.current += 1
                        this.list.push(...data)
                        this.total = total_count
                        if (this.list.length == total_count) {
                            this.isLoaded = true
                        } else {
                            this.isLoaded = false
                        }
                        return data
                    }
                    return []
                }).finally(() => {
                    this.searching = false
                })
            },
            async handleReachBottom(done) {
                await this.getLabelList()
                done()
            },
            handelLabelWrapShow() {
                this.labelWrapShow = true
            },
            handelLabelWrapCancel() {
                this.labelWrapShow = false
            },
            handleName(e) {
                let value = e.target.value.replace(/(^\s*)|(\s*$)/g, "")
                if ( value === '') {
                    return this.$Message.error(this.$t('pages.space.nameRequired'))
                }
                if (value.length > 32) {
                    return this.$Message.error(this.$t('pages.space.nameLength'))
                }
            },
            handleClear() {
                this.current = 1
                this.list = []
                this.getLabelList()
            },
            handleLoadingModalCancel() {
                this.loadingModalShow = false
                this.$emit('confirm')
            },
            hideFailedModal() {
                this.failedModalShow = false
                this.handleLoadingModalCancel()
                this.cancel()
            },
        },
        mounted() {}
    }
</script>
<style scoped lang="less">
@import "~less/color";
@deep: ">>>";
.iconClose {
    color: #999;
    &:hover {
        color: #444;
    }
}
.img-block-wrap {
    width: 666px;
    min-height: 280px;
    .img-block {
        position: relative;
        float: left;
        width: 210px;
        height: 210px;
        margin: 0 12px 14px 0;
        background: #b8babe;
        box-shadow: 0px 3px 6px 0px rgba(207,207,207,0.50);
        .upload {
            width: 100%;
            height: 100%;
            background: #e6e9ee;
            .upload-icon-wrap {
                position: relative;
                width: 210px;
                height: 210px;
                .icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: #fff;
                }

            }
        }
        .img-wrap {
            overflow: hidden;
            position: relative;
            width: 100%;
            height: 100%;
            img {
                cursor: zoom-in;
            }
            .icon-wrap {
                position: absolute;
                right: 5px;
                top: 5px;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: rgba(0,0,0,0.4);
                .icon-remove {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: #f2f2f2;
                }
            }
            .progress {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }
        .name {
            position: absolute;
            bottom: 0;
        }
    }
}
.right-bar {
    overflow-y: auto;
    max-height: calc(100vh - 239px);
    padding-left: 26px;
    .label {
        position: relative;
        margin: 10px 10px 0 0;
        .close {
            display: none;
            position: absolute;
            top: -5px;
            right: -5px;
        }
        &:hover {
            .close {
                display: block;
            }
        }
    }
}
.section {
    position: relative;
}
.left-bar {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 239px);
    border-right: 2px solid #f2f2f2;
}
.add-label-wrap {
    position: absolute;
    top: 10px;
    right: 210px;

    width: 260px;
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 2px 6px 2px rgba(0,0,0,0.16);
    z-index: 1;
    .add {
        margin-top: 10px;
        max-width: 200px;
        padding: 0 15px;
        height: 32px;
        background: #ececec;
        border: 1px solid #cacaca;
        border-radius: 15px;
        line-height: 32px;
        text-align: center;
        &:hover {
            color: @primary-color;
        }
    }
    .tooltip {
        display: block;
        .checkbox {
            overflow: hidden;
            display: block;
            max-width: 200px;
            text-overflow:ellipsis;
            white-space: nowrap;
        }
    }
}

.poptip {
    margin-right: 8px;
    text-align: left;
}
.poptip-close {
    position: absolute;
    top: 8px;
    right: 8px;
}
.name /deep/ .ivu-input {
    background: rgba(0,0,0,0.4);
    color: #f2f2f2;
    font-size: 15px;
}
.progress /deep/ .ivu-progress-inner {
    background: #979899;
}
.progress /deep/ .ivu-progress-bg {
    background: #fff !important;
}
.label /deep/ span {
    overflow: hidden;
    max-width: 50px;
    text-overflow:ellipsis;
    white-space: nowrap;
}
</style>