<template>
    <Modal
        class="wrap"
        v-model="modal"
        :title="$t('pages.space.checkFailed')"
        :mask-closable="false"
        :transfer="true"
        width="940"
        @on-visible-change="handleVisibleChange">
        <div class="content">
            <ul class="img-block-wrap clearfix">
                <li class="img-block" v-for="(item,index) in list" :key="index">
                    <div class="img-wrap">
                        <img class="image-center" :src="`cym/${item.cover_url}/thumbnail`">
                    </div>
                    <div class="name line-overflow">{{ item.name }}</div>
                </li>
            </ul>
        </div>
        <div slot="footer" class="modal-footer">
            <Button type="primary" @click="close">{{ $t('common.gotIt') }}</Button>
        </div>
    </Modal>  
</template>
<script>
    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            show: {
                default: false
            },
            list: {
                default: ()=> {
                    return []
                }
            }
        },
        data () {
            return {
                modal: false
            }
        },
        computed: {},
        watch: {
            show(val) {
                this.modal =  val
            }
        },
        methods: {
            handleVisibleChange(e) {
                if (!e) {
                    this.close()
                }
            },
            close() {
                this.$emit('cancel')
            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
.img-block-wrap {
    min-height: 280px;
    .img-block {
        position: relative;
        float: left;
        width: 210px;
        height: 210px;
        margin: 0 12px 14px 0;
        background: #b8babe;
        box-shadow: 0px 3px 6px 0px rgba(207,207,207,0.50);
        .img-wrap {
            overflow: hidden;
            position: relative;
            width: 100%;
            height: 100%;
        }
        .name {
            position: absolute;
            bottom: 0;
            width: 100%;
            background: rgba(0, 0, 0, 0.4);
            color: #f2f2f2;
            font-size: 15px;
        }
    }
}
</style>