<template>
    <Modal
        class="wrap"
        v-model="modal"
        :title="$t('pages.program.voicebroadcast')"
        :width="800"
        :mask-closable="false"
        @on-visible-change="handleVisibleChange">
        <Form ref="formValidate" class="left-bar" :model="formValidate" :rules="ruleValidate" :label-width="80">
            <Row>
                <Col span=9>
                    <FormItem :label="$t('pages.program.voice')" prop="speaker">
                        <Select transfer v-model="formValidate.speaker" placeholder="">
                            <Option v-for="item in speakerList" :value="item.value" :key="item.value">{{ item.text[lang]?item.text[lang]:item.text._ }}</Option>
                        </Select>
                    </FormItem>
                </Col>
                <Col span="9">
                    <FormItem :label="$t('pages.program.speed')" prop="speed">
                        <Select transfer v-model="formValidate.speed" placeholder="">
                            <Option v-for="item in speedList" :value="item.value" :key="item.value">{{ item.text[lang]?item.text[lang]:item.text._ }}</Option>
                        </Select>
                    </FormItem>
                </Col>
                <Col span="6">
                    <div class="button pointer" @click="handleAudition">{{ $t('pages.program.audition') }}
                        <Spin v-if="loading" fix size="small"></Spin>
                    </div>
                </Col>
            </Row>
            <FormItem :label="$t('common.content')" prop="content">
                <Input v-model="formValidate.content" type="textarea" :maxlength="2000" :autosize="{ minRows: 5, maxRows: 5 }" :placeholder="$t('pages.program.inputContent')"></Input>
            </FormItem>
            <FormItem :label="$t('pages.program.example')" prop="description">
                <Select v-model="formValidate.description" transfer @on-change="handleDescription">
                    <Option v-for="(item, index) in descriptionList" :value="item.slogan[lang]?item.slogan[lang]:item.slogan._" :key="index">{{ item.field[lang]?item.field[lang]:item.field._ }}({{ item.slogan[lang]?item.slogan[lang]:item.slogan._ }})</Option>
                </Select>
            </FormItem>
        </Form>
        <audio ref="media" class="media" controls height="100" width="60" :src="ttsSrc"></audio>
        <div slot="footer">
            <Button type="default" @click="cancel">{{ $t('common.cancel') }}</Button>
            <Button type="primary" @click="submit">{{ $t('pages.program.tts') }}</Button>
        </div>
    </Modal> 
</template>
<script>
    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            show: {
                default: false
            },
            ttsInfo: {
                default: () => {
                    return {}
                }
            },
            lang: {
                default: ''
            }
        },
        data () {
            return {
                modal: false,
                loading: false,
                formValidate: {
                    content: '',
                    speaker: '',
                    speed: '',
                    description: ''
                },
                ttsSrc: '',
                speedList: [],
                speakerList: [],
                descriptionList: []
            }
        },
        computed: {
            ruleValidate() {
                return {
                    content: [
                        { required: true, message: this.$t('common.required'), trigger: 'blur' }
                    ],
                    speaker: [
                        { required: true, message: this.$t('common.select'), trigger: 'change' }
                    ],
                    speed: [
                        { required: true,  message: this.$t('common.select'), trigger: 'change' }
                    ]
                }
            }
        },
        watch: {
            show(val) {
                this.modal = val
                if (!val) return
                for (let i = 0; i < this.speakerList.length; i++) {
                    if (this.speakerList[i].default) {
                        this.formValidate.speaker = this.speakerList[i].value
                        break
                    }
                }
                for (let i = this.speedList.length - 1; i >= 0; i--) {
                    if (this.speedList[i].default) {
                        this.formValidate.speed = this.speedList[i].value
                        break
                    }
                }
                if (this.ttsInfo) {
                    this.formValidate = {
                        content: this.ttsInfo.text,
                        speaker: this.ttsInfo.voice_creator,
                        speed: this.ttsInfo.speed
                    }
                }
            }
        },
        methods: {
            handleVisibleChange(e) {
                if(!e) {
                    this.cancel()
                }
            },
            cancel() {
                if (this.$refs.media) {
                    this.$refs.media.pause()
                    this.ttsSrc = ''
                }
                this.$refs['formValidate'].resetFields()
                this.$emit('cancel')
            },
            submit() {
                this.$refs['formValidate'].validate((valid) => {
                    if (valid) {
                        let params = {
                            media_type: 'tts',
                            text: this.formValidate.content,
                            voice_creator: this.formValidate.speaker,
                            speed: this.formValidate.speed
                        }
                        this.$emit('confirm', params)
                        this.cancel()
                    }
                })
            },
            handleAudition() {
                if (this.ttsSrc) {
                    window.URL.revokeObjectURL(this.ttsSrc)
                }
                let params = {
                    voice_creator: this.formValidate.speaker,
                    speed: this.formValidate.speed,
                    text: this.formValidate.content
                }
                this.$api.post('materials/tts/audition', params).then(({ data }) => {
                    let audioBlob = this.base64ToBlob(data.file, 'mp3');
                    this.ttsSrc = window.URL.createObjectURL(audioBlob);
                    this.$nextTick(() => {
                        this.$refs.media.play()
                    })
                })
            },
            base64ToBlob(base64, type) {
                let typeHeader = 'data:audio/mpeg;base64,'; // 定义base64 头部文件类型
                let audioSrc = typeHeader + base64; // 拼接最终的base64
                let arr = audioSrc.split(',');
                let array = arr[0].match(/:(.*?);/);
                let mime = (array && array.length > 1 ? array[1] : type) || type;
                // 去掉url的头，并转化为byte
                let bytes = window.atob(arr[1]);
                // 处理异常,将ascii码小于0的转换为大于0
                let ab = new ArrayBuffer(bytes.length);
                // 生成视图（直接针对内存）：8位无符号整数，长度1个字节
                let ia = new Uint8Array(ab);
                for (let i = 0; i < bytes.length; i++) {
                    ia[i] = bytes.charCodeAt(i);
                }
                return new Blob([ab], {
                    type: mime
                });
            },
            getSpeedList() {
                this.$api.get('materials/tts/speed_list').then(({ data=[] }) => {
                    this.speedList = data
                }).catch(() => {})
            },
            getSpeakerList() {
                this.$api.get('materials/tts/voice_creator_list').then(({ data=[] }) => {
                    this.speakerList = data
                }).catch(() => {})
            },
            getDescriptionList() {
                this.$api.get('materials/tts/description_list').then(({ data=[] }) => {
                    this.descriptionList = data
                })
            },
            handleDescription(e) {
                if  (e) {
                    this.formValidate.content += e
                }
            }
        },
        mounted() {
            this.getSpeedList()
            this.getSpeakerList()
            this.getDescriptionList()
        }
    }
</script>
<style scoped lang="less">
@import '~less/color';

.left-bar {
    display: inline-block;
    width: 80%;
    vertical-align: top;
}
.button {
    position: relative;
    margin-left: 20px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background: @primary-color;
    font-size: 12px;
    line-height: 38px;
    color: #ffffff;
    text-align: center;
}
.media {
    display: none;
}
</style>