<template>
    <Modal
        class="wrap"
        v-model="modal"
        :title="title2"
        :mask-closable="false"
        :closable="false"
        :transfer="true">
        <div class="loading-wrap">
            <Progress :percent="percent" />
        </div>
        <div slot="footer" class="modal-footer">
            <Poptip
                class="poptip"
                confirm
                placement="top-end"
                :itle="$t('pages.space.closeTip')"
                :cancel-text="$t('pages.space.wait')"
                :ok-text="$t('pages.space.confirmClose')"
                @on-ok="close">
                <Button type="primary">{{ $t('pages.space.hide') }}</Button>
            </Poptip>
        </div>
    </Modal> 
</template>
<script>
    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            show: {
                default: false
            },
            percent: {
                default: 0
            },
            title: {
                default() {
                    return this.$t('pages.space.checking')
                }
            }
        },
        data () {
            return {
                modal: false,
                title2: this.$t('pages.space.checking')
            }
        },
        computed: {
            currentLang() {
                return this.$store.state.lang
            }
        },
        watch: {
            currentLang() {
                this.updateDispaly()
            },
            title(val) {
                this.title2 = val
            },
            show(val) {
                this.modal = val
            }
        },
        methods: {
            updateDispaly() {
                this.title2 = this.$t('pages.space.checking')
            },
            close() {
                this.$emit('cancel')
            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
.loading-wrap {
    .loading-gif {
        display: block;
        margin: 0 auto;
    }
}
.poptip {
    margin-right: 8px;
    text-align: left;
}
</style>